import * as Highcharts from 'highcharts';

// 开门次数统计标签
const TimesNav = [{
    label: WordList.DateInputToday,
    key: 'Day',
    path: ''
}, {
    label: WordList.ProperAllTextThirtyDays,
    key: 'Month',
    path: ''
}];

// 开门次数统计图
const timesCharts = (timesData: {y: number, color: string}[]) => {
    let noData = false;
    if (timesData.every((num: {y: number, color: string}) => num.y === 0)) {
        noData = true;
    }
    Highcharts.chart('times-container', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [
                WordList.ProperAllTextPIN,
                WordList.DeliveryRfCard,
                WordList.ProperAllTextPhotos,
                WordList.ProperAllTextCall,
                WordList.NavInHtmlMenuApp
            ]
        },
        yAxis: noData ? {
            min: 0,
            max: 1,
            tickInterval: 0.2,
            title: {
                text: ''
            }
        } : {
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{y}'
                }
            }
        },
        series: [{
            name: '',
            data: timesData,
            type: 'column'
        }]
    });
};

// 统计图所需颜色分类
const addColor = (originData: number[]) => {
    const color = ['#18BAFD', '#FF7526', '#72CF46', '#F1BB46', '#8A8AFF'];
    const tempData: Array<{
        color: string;
        y: number;
    }> = [];
    originData.forEach((val, index) => {
        const tempObj = {
            color: color[index],
            y: originData[index]
        };
        tempData.push(tempObj);
    });
    return tempData;
};

export default null;
export {
    TimesNav,
    timesCharts,
    addColor
};